import {
  Address,
  CacheResponse,
  IncompleteList,
  Merchant,
  MerchantNextSchedulesResponse,
  MerchantSchedulesResponse,
} from '../queries/home/types';
import { weekDayToHuman } from '@onbeefapp/constants';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { getCurrentWeekDay } from '../utils/function';

type MerchantStore = {
  merchantCache?: CacheResponse['content'];
  merchant?: CacheResponse['content']['merchant'];
  clubs?: CacheResponse['content']['clubs'];
  loyalty_programme?: CacheResponse['content']['loyalty_programme'];
  links?: CacheResponse['content']['links'];
  delivery?: CacheResponse['content']['delivery'];
  hasCoupon?: CacheResponse['content']['hasCoupon'];
  accounts?: CacheResponse['content']['accounts'];
  categories?: CacheResponse['content']['categories'];
  schedulesOfWeek?: CacheResponse['content']['schedules'];
  payment_methods?: CacheResponse['content']['payment_methods'];
  filters?: CacheResponse['content']['filters'];
  crm_bonus?: CacheResponse['content']['crm_bonus'];
  address?: Address;

  incompleteList: IncompleteList[];
  // check if merchant is open at the current time
  isMerchantOpen?: boolean;
  onlyUnitProducts?: boolean;
  haveBank?: boolean;
  haveDeliveryPaymentMethods?: boolean;
  activePix?: boolean;
  activeCreditCard?: boolean;
  activeCheckoutLink?: boolean;
  // reason why it's closed
  isClosedReason?: string | null;
  feePix?: number;
  feeCreditCard?: number;
  // if the merchant is closed, when is the next time it will open
  // day of the week "Monday", "Tuesday", etc
  // start time "08:00"
  nextHour?: {
    day: string;
    start: string;
  };
  schedules?: MerchantSchedulesResponse;
  nextSchedules?: MerchantNextSchedulesResponse;
  assessments?: {
    quantity: number;
    avg: string;
  };

  setMerchant: (merchant: CacheResponse['content']) => void;
  setSchedules: (schedules?: MerchantSchedulesResponse) => void;
  setNextSchedules: (nextSchedules?: MerchantNextSchedulesResponse) => void;
};

const verifyIsMerchantOpen = (
  merchant: Merchant,
  schedules: MerchantSchedulesResponse,
) => {
  const currentWeekDay = getCurrentWeekDay();
  const scheduleForCurrentDay = schedules?.schedules?.find(
    (obj) => obj.day === currentWeekDay,
  );

  if (
    merchant?.status === 2 ||
    merchant?.subscription?.status === 'EXPIRED' ||
    merchant?.subscription?.status === 'TRIAL_EXPIRED'
  ) {
    return false;
  }

  if (
    schedules?.open === false &&
    schedules?.receive_orders_when_closed === true
  ) {
    return true;
  }

  if (
    scheduleForCurrentDay &&
    schedules?.receive_orders_when_closed === false
  ) {
    const currentHourAndMinute = new Date().toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    const start = scheduleForCurrentDay.start ?? '00:00';
    const end = scheduleForCurrentDay.end ?? '23:59';

    const isWithinSchedule =
      currentHourAndMinute >= start && currentHourAndMinute <= end;

    if (isWithinSchedule) {
      return scheduleForCurrentDay.closed !== 1;
    }
    return false;
  }

  return schedules?.open ?? false;
};

const verifyIsMerchantMessageOpen = (
  merchant: Merchant,
  schedules: MerchantSchedulesResponse,
) => {
  const isSubscriptionExpired =
    merchant?.subscription?.status === 'EXPIRED' ||
    merchant?.subscription?.status === 'TRIAL_EXPIRED';

  const isClosed = schedules?.open === false;
  const canReceiveOrdersWhenClosed = schedules?.receive_orders_when_closed;

  if (isSubscriptionExpired) {
    return 'Loja fechada, entre em contato com o suporte';
  }

  if (isClosed && canReceiveOrdersWhenClosed) {
    return `Loja fechada, deixe seu pedido agendado.`;
  }

  if (isClosed && !canReceiveOrdersWhenClosed) {
    return `Loja fechada.`;
  }

  return null;
};

const verifyIncompleteList = (
  incompleteList: IncompleteList[],
  merchant: CacheResponse['content']['merchant'],
  delivery: CacheResponse['content']['delivery'],
  address: CacheResponse['content']['address'],
  categories: CacheResponse['content']['categories'],
  schedules: CacheResponse['content']['schedules'],
  payments: CacheResponse['content']['payment_methods'],
) => {
  const updateStatus = (
    item: IncompleteList,
    data: string | any,
    minLength = 0,
  ) => {
    const isString = typeof data === 'string';
    const phone = isString && data.length > minLength;
    return {
      ...item,
      status: isString
        ? phone
        : data && Boolean(data != null || data.length > minLength),
    };
  };

  const incompleteCheck = incompleteList.map((item) => {
    switch (item.slug) {
      case 'phone':
        return updateStatus(item, merchant.phone);
      case 'delivery':
        return updateStatus(item, delivery);
      case 'address':
        return updateStatus(item, address);
      case 'category':
        return updateStatus(item, categories);
      case 'schedules':
        return updateStatus(item, schedules);
      case 'payments':
        return updateStatus(item, payments);
      default:
        return { ...item };
    }
  });

  return incompleteCheck;
};

export const useMerchantStore = create(
  persist<MerchantStore>(
    (set) => ({
      setSchedules: (schedules?: MerchantSchedulesResponse) =>
        set({
          schedules,
          isMerchantOpen: schedules?.open,
        }),
      setNextSchedules: (nextSchedules?: MerchantNextSchedulesResponse) =>
        set((state) => {
          let isMerchantOpen = true;
          let isClosedReason = null;
          const nextHour = {
            day: weekDayToHuman(nextSchedules?.next_schedule?.day) ?? '',
            start: nextSchedules?.next_schedule?.start ?? '',
          };
          if (state.merchant && state.schedules) {
            const open = verifyIsMerchantOpen(state.merchant, state.schedules);
            isMerchantOpen = open;
            const message = verifyIsMerchantMessageOpen(
              state.merchant,
              state.schedules,
            );
            isClosedReason = message;
          }
          return {
            ...state,
            nextSchedules,
            nextHour,
            isMerchantOpen,
            isClosedReason,
          };
        }),
      setMerchant: (merchant: CacheResponse['content']) => {
        set((state) => {
          const incompleteList = verifyIncompleteList(
            state.incompleteList,
            merchant.merchant,
            merchant.delivery,
            merchant.address,
            merchant.categories,
            merchant.schedules,
            merchant.payment_methods,
          );

          const bank = merchant?.accounts?.find((item) => item.type === 'BANK');
          const pix = bank?.methods?.find((item) => item === 'PIX');
          const creditCard = bank?.methods?.find(
            (item) => item === 'CREDIT_CARD',
          );
          const checkoutLink = bank?.active_link || false;
          const onlyUnitProducts = bank?.no_items_kg === 1;
          let haveBank = bank ? true : false;

          if (!pix && !creditCard && !checkoutLink) {
            haveBank = false;
          }

          const haveDeliveryPaymentMethods =
            merchant.payment_methods?.filter((item) => {
              if (item.name?.includes('Whatsapp')) return undefined;

              if (item.pivot && item.pivot.status === 1) {
                return item;
              }

              return undefined;
            }).length > 0;

          const account_fee_credit_card = merchant.accounts.find(
            (item) => item.type === 'BANK' && item?.fees?.status === true,
          );
          const account_fee_pix = merchant.accounts.find(
            (item) => item.type === 'BANK' && item?.fees?.status === true,
          );

          const feeCreditCard = account_fee_credit_card
            ? account_fee_credit_card.fees?.fee_credit_card
            : 0;
          const feePix = account_fee_pix ? account_fee_pix.fees?.fee_pix : 0;

          return {
            incompleteList: incompleteList,
            merchant: merchant.merchant,
            merchantCache: merchant,
            loyalty_programme: merchant.loyalty_programme,
            clubs: merchant.clubs,
            crm_bonus: merchant.crm_bonus,
            links: merchant.links,
            delivery: merchant.delivery,
            hasCoupon: merchant.hasCoupon,
            categories: merchant.categories,
            schedulesOfWeek: merchant.schedules,
            payment_methods: merchant.payment_methods,
            assessments: merchant.assessments,
            filters: merchant.filters,
            address: merchant.address,
            haveBank: haveBank,
            activePix: pix ? true : false,
            activeCreditCard: creditCard ? true : false,
            activeCheckoutLink: checkoutLink,
            haveDeliveryPaymentMethods,
            onlyUnitProducts,
            feeCreditCard,
            feePix,
          };
        });
      },
      incompleteList: [
        {
          slug: 'delivery',
          message: 'Cadastre as informações para entrega',
          status: true,
        },
        {
          slug: 'address',
          message: 'Cadastre o endereço da loja',
          status: true,
        },
        {
          slug: 'product',
          message: 'Cadastre um produto',
          status: true,
        },
        {
          slug: 'category',
          message: 'Cadastre uma categoria',
          status: true,
        },
        {
          slug: 'payments',
          message: 'Cadastre uma forma de pagamento',
          status: true,
        },
        {
          slug: 'schedules',
          message: 'Cadastre os horários de funcionamento',
          status: true,
        },
        {
          slug: 'phone',
          message: 'Cadastre um telefone',
          status: true,
        },
      ],
    }),
    { name: 'merchant', storage: createJSONStorage(() => sessionStorage) },
  ),
);
